import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Star from "../assets/landing/star.png";

import breakpoints from "../breakpoints";

const StarsContainer = styled.div`
  margin-top: 30px;
  margin-bottom: -10px;
  display: flex;
  justify-content: center;
`;

const StarContainer = styled.div`
  width: 20px;
  height: 20px;
  padding-left: 1px;
  padding-right: 1px;
`;

const StyledImg = styled.img`
  width: 100%;
`;

const ReviewStars = ({ children, rating }) => {
  return (
    <StarsContainer>
      {[...Array(rating)].map(() => (
        <StarContainer>
          <StyledImg src={Star}></StyledImg>
        </StarContainer>
      ))}
    </StarsContainer>
  );
};

export default ReviewStars;
