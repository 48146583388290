// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden !important;
  position: relative;
}

.App {
  text-align: center;
}

.PageContainer {
  position: relative;
  min-height: 100vh;
}

.ContentWrap {
  /* padding-bottom: 320px; */
}

@media only screen and (min-width: 780px) {
  .ContentWrap {
    /* padding-bottom: 180px; */
  }
}

#iframe {
  display: none;
}

.vsc-controller {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,WAAW;EACX,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":["html,\nbody {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  width: 100%;\n  overflow-x: hidden !important;\n  position: relative;\n}\n\n.App {\n  text-align: center;\n}\n\n.PageContainer {\n  position: relative;\n  min-height: 100vh;\n}\n\n.ContentWrap {\n  /* padding-bottom: 320px; */\n}\n\n@media only screen and (min-width: 780px) {\n  .ContentWrap {\n    /* padding-bottom: 180px; */\n  }\n}\n\n#iframe {\n  display: none;\n}\n\n.vsc-controller {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
