import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import breakpoints from "../breakpoints";

const StyledButton = styled.button`
  background-color: ${(props) => `${props.theme.colors.orange}`};
  border: none !important;
  min-width: 100px;
  padding: 5px 20px;
  color: white;
  font-family: ${(props) => `${props.theme.fonts.secondary}`};
  font-weight: ${(props) => `${props.theme.fontWeight.semiBold}`};
  font-size: 16px;
  cursor: pointer;
  font-size: 24px;
  &:hover {
    opacity: 0.9;
  }
  @media only screen and ${breakpoints.device.md} {
    padding: 10px 20px;
  }
`;

const BookButton = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default BookButton;
