import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Tab, TabList, TabPanel, Tabs } from "@zendeskgarden/react-tabs";

import breakpoints from "../breakpoints";

import Banner from "./Banner";
import BookButton from "../components/BookButton";
import MaxWidthWrapper from "../components/MaxWidthWrapper";
import FullWidthWrapper from "../components/FullWidthWrapper";
import P from "../components/P";
import ReviewStars from "../components/ReviewStars";

import HeroImage from "../assets/landing/heroImage.jpg";
import ValueProp1 from "../assets/landing/valueProp1b.jpg";
import ValueProp2 from "../assets/landing/valueProp2.png";
import ValueProp3 from "../assets/landing/valueProp3a.jpg";
import ValueProp3b from "../assets/landing/valueProp3b.jpeg";
import ValueProp4 from "../assets/landing/valueProp4.png";
import PedalsGif from "../assets/landing/pedals2.gif";
import PedalsPoster from "../assets/landing/pedalsPoster.png";
import PedalsMP4 from "../assets/landing/pedals2.mp4";
import FreeAndEasyHero from "../assets/landing/freeAndEasyHero.jpg";
import JoyRideHero from "../assets/landing/joyRideHero.png";
import SunriseHero from "../assets/landing/sunriseHero.jpeg";
import SunriseBreakfast from "../assets/landing/sunriseBreakfast.jpg";
import SunriseBreakfast1 from "../assets/landing/sunriseBreakfast1.jpeg";
import SunriseBreakfast2 from "../assets/landing/sunriseBreakfast2.jpeg";
import KayakKelongMenu from "../assets/landing/kayak&KelongMenu.jpg";
import SunsetBbqCollage from "../assets/landing/sunsetBbqCollage.png";
import FleetImage from "../assets/landing/fleetImage.jpg";
import Tandem from "../assets/landing/tandem.jpg";
import Single from "../assets/landing/single2.jpg";
import WhatsappWebButton from "../assets/landing/whatsappWebButton.png";
import QrCode from "../assets/landing/qrCode.png";
import Photo1 from "../assets/landing/photoReel/photo1.jpeg";
import Photo2 from "../assets/landing/photoReel/photo2.jpg";
import Photo3 from "../assets/landing/photoReel/photo3.jpeg";
import Photo4 from "../assets/landing/photoReel/photo4.jpg";
import Photo5 from "../assets/landing/photoReel/photo5.jpg";
import Photo6 from "../assets/landing/photoReel/photo6.jpg";
import Photo7 from "../assets/landing/photoReel/photo7.jpg";
import Photo8 from "../assets/landing/photoReel/photo8.jpg";
import Photo9 from "../assets/landing/photoReel/photo9.jpg";

const LandingWrapper = styled.div`
  padding-bottom: 2.5em;
`;

const HeroImageContainer = styled.div`
  background-image: url(${HeroImage});
  height: 200px;
  width: 100%;
  position: relative;
  background-position: bottom;
  background-size: cover;

  @media only screen and ${breakpoints.device.sm} {
    height: 85vh;
    background-position: center;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 80vh;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        white 100%
      );
      opacity: 1;
    }
  }
`;

const HeroImageText = styled.h1`
  display: none;
  @media only screen and ${breakpoints.device.sm} {
    display: block;
    position: absolute;
    top: 10px;
    width: 100%;
    font-family: ${(props) => `${props.theme.fonts.primary}`};
    font-size: ${(props) => `${props.theme.fontSize.medium}`};
    top: 100px;
    font-size: ${(props) => `${props.theme.fontSize.xxLarge}`};
    letter-spacing: 4px;
  }
`;
const TaglineContainer = styled.div`
  @media only screen and ${breakpoints.device.sm} {
    width: 500px;
  }
  margin: 0 auto;
  padding: 1em;
`;

const Tagline = styled.p`
  font-size: ${(props) => `${props.theme.fontSize.large}`};
  font-family: ${(props) => `${props.theme.fonts.secondary}`};
  @media only screen and ${breakpoints.device.sm} {
    font-size: ${(props) => `${props.theme.fontSize.xLarge}`};
  }
`;

const Divider = styled.hr`
  border-top: 1px solid grey;
  max-width: ${breakpoints.size.md};
  width: 80%;
`;

const ValuePropsWrapper = styled.div`
  padding: 2em;
  padding-top: 20px;
  > * {
    @media only screen and ${breakpoints.device.sm} {
      &:nth-child(2n) p {
        padding-left: 2em;
      }
    }
  }
`;
const ValuePropContainer = styled.div`
  align-items: center;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  @media only screen and ${breakpoints.device.sm} {
    flex-direction: row;
  }
`;

const ValuePropText = styled.p`
  font-family: ${(props) => `${props.theme.fonts.primary}`};
  font-weight: ${(props) => `${props.theme.fontWeight.medium}`};
  flex: 2;
  font-size: ${(props) => `${props.theme.fontSize.medium}`};
  @media only screen and ${breakpoints.device.sm} {
    text-align: left;
    margin-right: 2em;
    font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
  }
`;

const ValuePropTextOdd = styled.p`
  font-family: ${(props) => `${props.theme.fonts.primary}`};
  font-weight: ${(props) => `${props.theme.fontWeight.medium}`};
  flex: 1;
  font-size: ${(props) => `${props.theme.fontSize.medium}`};
  order: 2;
  @media only screen and ${breakpoints.device.sm} {
    text-align: left;
    margin-right: 2em;
    font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
    order: unset;
  }
`;

const ValuePropImgDiv = styled.div`
  flex: 4;
  width: 100%;
`;

const StyledImg = styled.img`
  width: 100%;
`;

const StyledGif = styled(StyledImg)`
  object-fit: cover;
  object-position: center bottom;
  height: 300px;
  @media only screen and ${breakpoints.device.sm} {
    height: 500px;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  @media only screen and ${breakpoints.device.sm} {
    margin-top: -70%;
  }
`;

const ExploreContentContainer = styled.div`
  > h2 {
    margin-top: 0;
    font-family: ${(props) => `${props.theme.fonts.secondary}`};
    font-size: ${(props) => `${props.theme.fontSize.xLarge}`};
  }
`;

const TripTitleContainer = styled.div`
  align-items: center;
  display: flex;
  > p {
    font-family: ${(props) => `${props.theme.fonts.primary}`};
    font-weight: ${(props) => `${props.theme.fontWeight.medium}`};
    margin-top: 0;
    margin-bottom: 0;
    font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
    margin-right: 20px;
    @media only screen and ${breakpoints.device.sm} {
      font-size: ${(props) => `${props.theme.fontSize.large}`};
    }
  }
`;

const TripsHeroImgDiv = styled.div`
  margin-top: 2em;
  width: 100%;
  margin-bottom: 2em;
`;

const TripsBlurb = styled.div`
  text-align: left;
  margin-top: 2em;
  p {
    font-size: ${(props) => `${props.theme.fontSize.medium}`};
    @media only screen and ${breakpoints.device.sm} {
      font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
    }
  }
`;

const FleetWrapper = styled.div`
  padding: 2em;
  > h2 {
    font-family: ${(props) => `${props.theme.fonts.secondary}`};
    font-size: 30px;
    margin-top: 0;
  }
  p {
    font-size: ${(props) => `${props.theme.fontSize.medium}`};
    @media only screen and ${breakpoints.device.sm} {
      font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
    }
  }
  @media only screen and ${breakpoints.device.sm} {
    > h2 {
      font-size: 40px;
    }
  }
`;

const KayakTypesContainer = styled.div`
  display: flex;
  padding-top: 1em;
  flex-direction: column;
  @media only screen and ${breakpoints.device.sm} {
    flex-direction: row;
  }
`;

const KayakTypeContainer = styled.div`
  flex: 1;
  padding: 0.2em;
  > a {
    text-decoration-color: black !important;
  }
`;

const KayakImgContainer = styled.div`
  // height: 120px;
  @media only screen and ${breakpoints.device.sm} {
    // height: 300px;
  }
  img {
    // height: 100%;
  }
`;

const HelpContentContainer = styled.div`
  > h2 {
    font-family: ${(props) => `${props.theme.fonts.secondary}`};
    font-size: 30px;
    margin-top: 0;
  }
  a {
    color: black !important;
  }
  padding: 1em;
  margin: 0 auto;
  p {
    font-size: ${(props) => `${props.theme.fontSize.medium}`};
    @media only screen and ${breakpoints.device.sm} {
      font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
    }
  }
  @media only screen and ${breakpoints.device.sm} {
    > h2 {
      font-size: 40px;
    }
  }
`;

const PhotosContainer = styled.div`
  padding: 0em 2em;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 2em;
  @media only screen and ${breakpoints.device.sm} {
    margin-bottom: 2em;
  }
`;

const PhotoContainer = styled.div`
  flex: 1 1 30%; /*grow | shrink | basis */
  margin: 0.5em;
`;

const ReviewsContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3em;
  margin-bottom: 3em;
  width: 90%;
  @media only screen and ${breakpoints.device.sm} {
    margin-bottom: 2em;
    margin-bottom: 3em;
  }
`;

const ReviewContainer = styled.div`
  background: ${(props) => `${props.theme.colors.lightGrey}`};
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  min-height: 240px;
  padding: 1.5em;
  > p {
    font-size: 15px;
  }
  @media only screen and ${breakpoints.device.sm} {
    width: 40%;
    height: 300px;
  }
`;

const ReviewContent = styled.div`
  min-height: 160px;
  padding-top: 10px;
  > h2 {
    font-family: ${(props) => `${props.theme.fonts.primary}`};
    font-size: 18px;
    margin-top: 0;
  }
  > p {
    font-size: 15px;
  }
  @media only screen and ${breakpoints.device.sm} {
    height: 180px;
    > h2 {
      font-size: 20px;
    }
  }
`;

const QRCodeContainer = styled.div`
  margin-top: 20px;
  width: 190px;
  margin-bottom: 20px;
  @media only screen and ${breakpoints.device.sm} {
    width: 300px;
  }
`;

const Table = styled.table`
  text-align: left;
  width: 100%;
  td {
    display: block;
    padding-bottom: 1em;
    font-family: ${(props) => `${props.theme.fonts.primary}`};
    vertical-align: top;
    font-size: ${(props) => `${props.theme.fontSize.medium}`};
    @media only screen and ${breakpoints.device.sm} {
      margin-top: 3em;
      font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
      display: table-cell;
    }
  }
`;

const TableWithBorder = styled.table`
  border-collapse: collapse;
  background-color: white;
  // width: 100%;
  td {
    vertical-align: middle;
    display: table-cell !important;
    padding: 10px !important;
    border: 1px solid black;
    @media only screen and ${breakpoints.device.sm} {
      min-width: 100px;
      padding: 1em !important;
    }
  }
`;

const IFrameWrapper = styled.div`
  width: 100%;
`;

const BottomCTAContainer = styled.div`
  margin-bottom: 1em;
  margin-top: 2.5em;
`;

const MobileCTAContainer = styled.div`
  margin-bottom: 3em;
  margin-top: 0em;
  @media only screen and ${breakpoints.device.sm} {
    display: none;
  }
`;

const StyledTabList = styled(TabList)`
  text-align: left;
`;

const StyledTab = styled(Tab)`
  padding: 5px 14px 10px;
  font-family: ${(props) => `${props.theme.fonts.primary}`};
  > p {
    font-size: ${(props) => `${props.theme.fontSize.xxMedium}`};
  }
  font-weight: ${(props) => `${props.theme.fontWeight.semiBold}`};
  @media only screen and ${breakpoints.device.sm} {
    > p {
      font-size: ${(props) => `${props.theme.fontSize.medium}`};
    }
    padding: 10px 28px 20px;
  }
`;

const StyledNewTag = styled.div`
  display: inline-block !important;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 3px;
  background-color: ${(props) => `${props.theme.colors.orange}`};
  padding-left: 5px;
  padding-right: 5px;
  > p {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
    color: white;
  }
  @media only screen and ${breakpoints.device.sm} {
    padding-right: 10px;
    padding-left: 10px;
    > p {
      font-size: ${(props) => `${props.theme.fontSize.large}`};
    }
    margin-right: 10px;
  }
`;

const TdCategory = styled.td`
  padding-bottom: 0 !important;
  @media only screen and ${breakpoints.device.md} {
    padding-bottom: 1em !important;
  }
`;

const StyledTabs = styled(Tabs)`
  display: block;
  @media only screen and ${breakpoints.device.md} {
    display: table;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  margin-left: 0 !important;
  @media only screen and ${breakpoints.device.md} {
    margin-left: 32px;
  }
`;

const MeetingPointComponent = () => (
  <tr>
    <TdCategory>
      <b>Meeting Point</b>
    </TdCategory>
    <td>
      Pasir Ris Beach Park, to the left of KOKOMO Beach Club
      <br />
      <br />
      <IFrameWrapper>
        <iframe
          title="meeting-point-2"
          src="https://www.google.com/maps/d/u/2/embed?mid=19_puaN-AsiVnivISN_gBYFk2lVd5Ubub"
          width="100%"
          height="400"
        ></iframe>
      </IFrameWrapper>
      <br />
      Nearest carparks:
      <ul>
        <li>Pasir Ris Beach Park Carpark E (paying)</li>
        <li>Pasir Ris Beach Park Carpark F (non-paying)</li>
      </ul>
    </td>
  </tr>
);

const PaymentModeComponent = () => (
  <tr>
    <TdCategory>
      <b>Payment Modes</b>
    </TdCategory>
    <td>
      We accept the following payment methods:
      <ul>
        <li>PayNow</li>
        <ul>
          <li>Via UEN Number: T21LL1113D</li>
          <li>
            Via QR code
            <QRCodeContainer>
              <StyledImg src={QrCode} alt="QR Code"></StyledImg>
            </QRCodeContainer>
          </li>
        </ul>
        <br />
        <li>Bank Transfer (DBS Account No.: 0720438000)</li>
      </ul>
    </td>
  </tr>
);

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  componentDidMount() {
    const iFrame = document.getElementById("iframe");
    iFrame.style.display = "none";
  }

  handleTabSelect = (index) => {
    this.setState({ activeTab: index });
  };

  render() {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const isSmallDevice = window.innerWidth <= 768;

    console.log("isSmallDevice?", isSmallDevice);

    return (
      <LandingWrapper>
        {/* <Banner /> */}
        <HeroImageContainer>
          <HeroImageText>COME KAYAKING WITH US!</HeroImageText>
        </HeroImageContainer>
        <TaglineContainer>
          <Tagline>
            Welcome onboard to Kayak Pools! <br /> Explore the tropical beauty
            of Singapore's northeastern coastline in our premium Hobie pedal
            kayaks.
          </Tagline>
        </TaglineContainer>
        <MobileCTAContainer>
          <Link to="/book">
            <BookButton>BOOK YOUR KAYAKS TODAY</BookButton>
          </Link>
        </MobileCTAContainer>
        <Divider></Divider>
        <MaxWidthWrapper>
          <ValuePropsWrapper>
            <ValuePropContainer>
              <ValuePropTextOdd>
                Single and tandem kayaks available for solo and group bookings.
              </ValuePropTextOdd>
              <ValuePropImgDiv>
                <StyledImg
                  src={ValueProp1}
                  alt="single and tandem kayaks"
                ></StyledImg>
              </ValuePropImgDiv>
            </ValuePropContainer>
            <ValuePropContainer>
              <ValuePropImgDiv>
                <StyledImg src={ValueProp2} alt="kayak pasir ris"></StyledImg>
              </ValuePropImgDiv>
              <ValuePropText>
                Perfect for cruising seamlessly around calm Pasir Ris waterways.
              </ValuePropText>
            </ValuePropContainer>
            <ValuePropContainer>
              <ValuePropTextOdd>
                Or embark on the most thrilling fishing trips out in the open
                sea!
              </ValuePropTextOdd>
              <ValuePropImgDiv>
                <StyledImg src={ValueProp3b} alt="kayak fishing"></StyledImg>
              </ValuePropImgDiv>
            </ValuePropContainer>
            <ValuePropContainer>
              <ValuePropImgDiv style={{ overflow: "hidden" }}>
                <StyledGif src={PedalsGif} alt="leg pedal kayak"></StyledGif>
              </ValuePropImgDiv>
              <ValuePropText>
                Unique leg pedal propulsion system for a completely hands free
                experience.
              </ValuePropText>
            </ValuePropContainer>
          </ValuePropsWrapper>
        </MaxWidthWrapper>
        <FullWidthWrapper>
          <MaxWidthWrapper>
            <ExploreContentContainer id={"trips"}>
              <h2>TRIPS</h2>
              <br />
              <StyledTabs isVertical={isSmallDevice}>
                <StyledTabList>
                  <StyledTab item="tab-sunrise-tour">
                    <StyledNewTag>
                      <P secondaryFont>NEW!</P>
                    </StyledNewTag>
                    <P
                      style={{
                        display: "inline-block",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Sunrise Tour
                    </P>
                  </StyledTab>
                  <StyledTab item="tab-kayak-&-kelong">
                    <StyledNewTag>
                      <P secondaryFont>NEW!</P>
                    </StyledNewTag>
                    <P
                      style={{
                        display: "inline-block",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Kayak & Kelong
                    </P>
                  </StyledTab>
                  <StyledTab item="tab-sunrise-bbq-fishing-tour">
                    <StyledNewTag>
                      <P secondaryFont>NEW!</P>
                    </StyledNewTag>
                    <P
                      style={{
                        display: "inline-block",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Sunset BBQ Fishing Tour
                    </P>
                  </StyledTab>

                  <StyledTab item="tab-free-and-easy">
                    <P
                      style={{
                        display: "inline-block",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Free & Easy
                    </P>
                  </StyledTab>
                  {/* <StyledTab item="tab-joy-ride">
                    <StyledNewTag>
                      <P secondaryFont>NEW!</P>
                    </StyledNewTag>
                    &nbsp;
                    <P
                      style={{
                        display: "inline-block",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Joy Rides
                    </P>
                  </StyledTab> */}
                </StyledTabList>
                <StyledTabPanel item="tab-sunrise-tour">
                  <TripsHeroImgDiv>
                    <StyledImg
                      src={SunriseHero}
                      alt="sunrise tour kayak booking"
                    ></StyledImg>
                  </TripsHeroImgDiv>
                  <TripsBlurb>
                    <P>
                      Embark on a rejuvenating journey and kickstart your day
                      with our{" "}
                      <b>
                        guided kayak Sunrise Tour!
                        <br />
                        <br />
                      </b>
                      Glide through tranquil waters and{" "}
                      <b>experience a magical sunrise out in the open sea</b>.
                      The adventure concludes with a{" "}
                      <b>delectable breakfast </b> that will tantalize your
                      taste buds, all for just <b>$78 per person</b>. <br />
                      <br />
                      Unwind, reconnect with nature, and make each sunrise a
                      moment to cherish.
                    </P>
                  </TripsBlurb>

                  <TripsHeroImgDiv>
                    <StyledImg
                      src={SunriseBreakfast1}
                      alt="sunrise tour kayak booking"
                    ></StyledImg>
                  </TripsHeroImgDiv>
                  <TripsHeroImgDiv>
                    <StyledImg
                      src={SunriseBreakfast}
                      alt="sunrise tour kayak booking"
                    ></StyledImg>
                  </TripsHeroImgDiv>
                  <TripsHeroImgDiv>
                    <StyledImg
                      src={SunriseBreakfast2}
                      alt="sunrise tour kayak booking"
                    ></StyledImg>
                  </TripsHeroImgDiv>
                  <Table>
                    <tr>
                      <TdCategory>
                        <b>Itinerary</b>
                      </TdCategory>
                      <td>
                        Accompanied kayaking for 2 hours, followed by breakfast
                        till 10am
                      </td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Launch Time</b>
                      </TdCategory>
                      <td>Before 6am</td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Kayak Type</b>
                      </TdCategory>
                      <td>Single or Tandem kayaks</td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Group size</b>
                      </TdCategory>
                      <td style={{ paddingRight: 0 }}>
                        <TableWithBorder>
                          <tr>
                            <td>Min size</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td>Max size</td>
                            <td>10</td>
                          </tr>
                        </TableWithBorder>
                      </td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Rates</b>
                      </TdCategory>
                      <td style={{ paddingRight: 0 }}>
                        <TableWithBorder>
                          <tr>
                            <td>Per person</td>
                            <td>$78</td>
                          </tr>
                        </TableWithBorder>
                        <br />
                        {/* <Link to="/book">
                          <BookButton>BOOK NOW</BookButton>
                        </Link> */}
                      </td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Booking</b>
                      </TdCategory>
                      <td>
                        Contact us via Whatsapp at{" "}
                        <a href="tel:+6582995118">
                          <b>+65&nbsp;8299&nbsp;5118</b>
                        </a>{" "}
                        to book!
                      </td>
                    </tr>
                    <br />
                    <PaymentModeComponent />
                    <MeetingPointComponent />
                  </Table>
                </StyledTabPanel>
                <StyledTabPanel item="tab-kayak-&-kelong">
                  <TripsBlurb>
                    <P>
                      Gather your crew of 10 for a thrilling 4-hour escapade,
                      starting with an hour of <b>guided kayaking</b> from Pasir
                      Ris Beach to{" "}
                      <a
                        href="https://smithmarine.com.sg/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <b>Smith Marine Floating Seafood Restaurant</b>
                      </a>
                      .
                      <br />
                      <br /> Get ready to feast on an <b>8-course lunch</b> at
                      Smith Marine that will re-energise your fuel tank! <br />
                      <br />
                      Afterwards, embark on a westwards journey to soak in the
                      views of <b>kelong fish farms</b>, conquer the challenge
                      of <b>crossing the shipping channel</b>
                      , before making your way back to shore.
                      <br />
                    </P>
                    <TripsHeroImgDiv>
                      <StyledImg
                        src={KayakKelongMenu}
                        alt="kayak & kelong lunch menu"
                      ></StyledImg>
                    </TripsHeroImgDiv>
                  </TripsBlurb>
                  <Table>
                    <tr>
                      <TdCategory>
                        <b>Itinerary</b>
                      </TdCategory>
                      <td>
                        🛶 Accompanied kayaking from Meeting Point to Smith
                        Marine Floating Restaurant <br />
                        🍛 Lunch <br />
                        🛶 Accompanied kayaking to view fish farms <br />
                        🛶 Accompanied kayaking to cross the shipping channel
                        and head back to shore
                      </td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Launch Time</b>
                      </TdCategory>
                      <td>[TBC]</td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Kayak Type</b>
                      </TdCategory>
                      <td>Tandem kayaks</td>
                    </tr>
                    <br />
                    <tr>
                      <TdCategory>
                        <b>Group size</b>
                      </TdCategory>
                      <td>10 pax (fixed)</td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Rates</b>
                      </TdCategory>
                      <td style={{ paddingRight: 0 }}>
                        <TableWithBorder>
                          <tr>
                            <td>For 10 pax</td>
                            <td>$880</td>
                          </tr>
                        </TableWithBorder>
                        <br />
                        {/* <Link to="/book">
                          <BookButton>BOOK NOW</BookButton>
                        </Link> */}
                      </td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Booking</b>
                      </TdCategory>
                      <td>
                        Contact us via Whatsapp at{" "}
                        <a href="tel:+6582995118">
                          <b>+65&nbsp;8299&nbsp;5118</b>
                        </a>{" "}
                        to book!
                      </td>
                    </tr>

                    <br />
                    <PaymentModeComponent />
                    <MeetingPointComponent />
                  </Table>
                </StyledTabPanel>
                <StyledTabPanel item="tab-sunrise-bbq-fishing-tour">
                  <TripsBlurb>
                    <P>
                      🎣🌅 <b>Sunset Kayak Fishing with Private BBQ Feast!</b>
                      <br />
                      <br />
                      Prepare for an unforgettable journey along the shimmering
                      waters of the coast! Our accompanied{" "}
                      <b>sunset fishing tour</b> offers an exhilarating
                      experience filled with thrilling catches, capped off with
                      a <b>private BBQ</b> under the stars for a memorable
                      evening
                      <br />
                      <br />
                      🌟 Don't miss out on this thrilling excursion!{" "}
                      <b>Reserve your spot now </b>for an evening of fun, food,
                      and fantastic memories! 🌟
                      <br />
                    </P>
                    <TripsHeroImgDiv>
                      <StyledImg
                        src={SunsetBbqCollage}
                        alt="sunset bbq collage"
                      ></StyledImg>
                    </TripsHeroImgDiv>
                    <P></P>
                  </TripsBlurb>
                  <Table>
                    <tr>
                      <TdCategory>
                        <b>Itinerary</b>
                      </TdCategory>
                      <td>
                        🎣 <b>Fishing Delights</b>: Get ready for an epic
                        angling adventure with fishing rods, hooks, and baits
                        provided! <br />
                        <br />
                        🍖🔥 <b>BBQ Extravaganza at Private Location</b>: Dive
                        into a mouth-watering spread featuring fresh fish,
                        succulent prawns, tender squids, savory chicken wings,
                        homemade meat skewers, refreshing salad, your choice of
                        rice or macaroni pasta, and a delightful selection of
                        fruits.
                      </td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Launch Time</b>
                      </TdCategory>
                      <td>Anytime after 4pm</td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Kayak Type</b>
                      </TdCategory>
                      <td>
                        Tandem kayaks (Single kayaks provided for odd-numbered
                        groups)
                      </td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Group size</b>
                      </TdCategory>
                      <td style={{ paddingRight: 0 }}>
                        <TableWithBorder>
                          <tr>
                            <td>Min size</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td>Max size</td>
                            <td>10</td>
                          </tr>
                        </TableWithBorder>
                      </td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Rates</b>
                      </TdCategory>
                      <td style={{ paddingRight: 0 }}>
                        <TableWithBorder>
                          <tr>
                            <td>Per person</td>
                            <td>$98</td>
                          </tr>
                        </TableWithBorder>
                        <br />
                        {/* <Link to="/book">
                          <BookButton>BOOK NOW</BookButton>
                        </Link> */}
                      </td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Booking</b>
                      </TdCategory>
                      <td>
                        Contact us via Whatsapp at{" "}
                        <a href="tel:+6582995118">
                          <b>+65&nbsp;8299&nbsp;5118</b>
                        </a>{" "}
                        to book!
                      </td>
                    </tr>
                    <br />
                    <PaymentModeComponent />
                    <MeetingPointComponent />
                  </Table>
                </StyledTabPanel>
                <StyledTabPanel item="tab-free-and-easy">
                  <TripsHeroImgDiv>
                    <StyledImg
                      src={FreeAndEasyHero}
                      alt="free and easy kayak booking"
                    ></StyledImg>
                  </TripsHeroImgDiv>
                  <TripsBlurb>
                    <P>
                      Go sightseeing in the Pasir Ris sea and kayak amidst
                      kelongs (local fishing farms), visit a floating restaurant
                      in the middle of the sea, island hop to Pulau Ubin and
                      Coney Island, or fish to your heart’s content. The
                      possibilities are endless. <br />
                      <br />
                      <b>
                        We’ll launch, recover and wash the kayaks for you.
                      </b>{" "}
                      <br />
                      We just need you to be present and have fun!
                    </P>
                  </TripsBlurb>
                  <Table>
                    <tr>
                      <TdCategory>
                        <b>Duration</b>
                      </TdCategory>
                      <td>
                        Up to 8 hours
                        <br />
                        Latest return time: 5pm
                      </td>
                    </tr>
                    <tr>
                      <TdCategory>
                        <b>Launch Time</b>
                      </TdCategory>
                      <td>Anytime between 8am-11am</td>
                    </tr>

                    <tr>
                      <TdCategory>
                        <b>Rates</b>
                      </TdCategory>
                      <td>
                        <TableWithBorder>
                          <tr>
                            <td>Single Kayak (sits 1)</td>
                            <td>$60</td>
                          </tr>
                          <tr>
                            <td>Tandem Kayak (sits 2)</td>
                            <td>
                              First 5 hours: $60
                              <br />
                              Each additional hour: $15/hour
                            </td>
                          </tr>
                        </TableWithBorder>
                        <br />
                        <Link to="/book">
                          <BookButton>BOOK NOW</BookButton>
                        </Link>
                        <p style={{ fontSize: "14px" }}>
                          <i>
                            *Please note that a surcharge of $15/hour applies
                            from 5.30pm onwards
                          </i>
                        </p>
                      </td>
                    </tr>
                    <PaymentModeComponent />
                    <MeetingPointComponent />
                  </Table>
                </StyledTabPanel>
                {/* <StyledTabPanel item="tab-joy-ride">
                  <TripsHeroImgDiv>
                    <StyledImg
                      src={JoyRideHero}
                      alt="joy ride kayak booking"
                    ></StyledImg>
                  </TripsHeroImgDiv>
                  <TripsBlurb>
                    <P>
                      Come join us for our June School Holiday Special - 2 hour
                      joy rides on our premium Hobie leg-pedal kayaks. For a
                      limited time, we will provide complimentary guided support
                      for the first 30 minutes to help you explore Pasir Ris
                      waters.
                      <br />
                      <br />
                      What is better than bonding with your family with a fun
                      activity out at sea. Limited slots available so book now!
                    </P>
                  </TripsBlurb>
                  <Table>
                    <tr>
                      <td>
                        <b>Duration</b>
                      </td>
                      <td>2 hours</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Launch Time</b>
                      </td>
                      <td>
                        10am - 12pm <br /> 1pm - 3pm <br /> 4pm - 6pm
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Rates</b>
                      </td>
                      <td style={{ paddingRight: 0 }}>
                        <TableWithBorder>
                          <tr>
                            <td>Tandem kayak</td>
                            <td>10am-12pm</td>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <td>Tandem kayak</td>
                            <td>1pm-3pm</td>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <td>Tandem kayak</td>
                            <td>4pm-6pm</td>
                            <td>$60</td>
                          </tr>
                        </TableWithBorder>
                        <br />
                        <Link to="/book">
                          <BookButton>BOOK NOW</BookButton>
                        </Link>
                      </td>
                    </tr>
                    <PaymentModeComponent />
                    <MeetingPointComponent />
                  </Table>
                  <IFrameWrapper>
                    <iframe
                      title="meeting-point"
                      src="https://www.google.com/maps/d/u/2/embed?mid=19_puaN-AsiVnivISN_gBYFk2lVd5Ubub"
                      width="100%"
                      height="400"
                    ></iframe>
                  </IFrameWrapper>
                </StyledTabPanel> */}
              </StyledTabs>
            </ExploreContentContainer>
          </MaxWidthWrapper>
        </FullWidthWrapper>
        <MaxWidthWrapper>
          <FleetWrapper>
            <h2>Our Fleet</h2>
            <P>
              We offer premium Hobie pedal kayaks that are well-maintained and
              safe for use. We thoroughly clean and disinfect our kayaks after
              every trip.
            </P>
            <TripsHeroImgDiv>
              <StyledImg src={FleetImage}></StyledImg>
            </TripsHeroImgDiv>
            <P>Our fleet consists of single and tandem kayaks:</P>
            <KayakTypesContainer>
              <KayakTypeContainer>
                <KayakImgContainer>
                  <P semiBold>Single</P>
                  <StyledImg src={Single}></StyledImg>
                </KayakImgContainer>
                <P>
                  Hobie Mirage Outback <br /> (both new & old models available){" "}
                </P>
                <P style={{ fontSize: "14px", fontStyle: "italic" }}>
                  Note: kayak seat may vary depending on model
                </P>
              </KayakTypeContainer>
              <KayakTypeContainer>
                <KayakImgContainer>
                  <P semiBold>Tandem</P>
                  <StyledImg src={Tandem}></StyledImg>
                </KayakImgContainer>
                <P>Hobie Mirage Compass Duo</P>
              </KayakTypeContainer>
            </KayakTypesContainer>
          </FleetWrapper>
        </MaxWidthWrapper>

        <MaxWidthWrapper>
          <h2>Customer Reviews</h2>
          <ReviewsContainer>
            <ReviewContainer>
              <ReviewContent>
                <h2>"Excellent service & clean kayak"</h2>
                <P>
                  "Uncle Foo and his wife provides excellent service and
                  flexible time. Will definitely rent from them again. They're
                  so so friendly and kind."
                </P>
              </ReviewContent>
              <ReviewStars rating={5} />
              <P>ZhaoRong</P>
            </ReviewContainer>
            <ReviewContainer>
              <ReviewContent>
                <h2>"Superb service!"</h2>
                <P>
                  "Love paddling on the hobie outback. Uncle Foo is also super
                  friendly and very good in handling feedback on the kayaks!
                  Overall a very satisfactory experience. Looking forward to
                  more sea kayaking adventure!"
                </P>
              </ReviewContent>
              <ReviewStars rating={5} />
              <P>Jurismah Jumali</P>
            </ReviewContainer>
            <ReviewContainer>
              <ReviewContent>
                <h2>"Hard to beat"</h2>
                <P>
                  "Great rental service with a price thats hard to beat. Uncle
                  Foo is ever so friendly, helpful and cheery. Kayaks are well
                  maintained and it good condition. You will not regret renting
                  from him."
                </P>
              </ReviewContent>
              <ReviewStars rating={5} />
              <P>EJ Tan</P>
            </ReviewContainer>
            <ReviewContainer>
              <ReviewContent>
                <h2>"Affordable premium kayaks with great service!"</h2>
                <P>"Hightly recommended!!!"</P>
              </ReviewContent>
              <ReviewStars rating={5} />
              <P>Michael Yong</P>
            </ReviewContainer>
            <ReviewContainer>
              <ReviewContent>
                <h2>"Excellent kayaks"</h2>
                <P>
                  "Kayakpools is by far the best rental service in Singapore
                  period. I will definitely come again. Mr and Mrs Foo are
                  knowledgeable, professional and honest."
                </P>
              </ReviewContent>
              <ReviewStars rating={5} />
              <P>Dominic Tan</P>
            </ReviewContainer>

            <ReviewContainer>
              <ReviewContent>
                <h2>"The wonderful kayaking trip"</h2>
                <P>
                  "We have a good kayaking trip this morning under the beautiful
                  weather and Mr Foo and team never failed to bring their good
                  service to the family. We will definitely be back again soon
                  with more family members on board. Thank you very much"
                </P>
              </ReviewContent>
              <ReviewStars rating={5} />
              <P>Andrew Lim</P>
            </ReviewContainer>
            <ReviewContainer>
              <ReviewContent>
                <h2>"Perfect trip with a perfect host"</h2>
                <P>
                  "Mr and Mrs Foo were fantastic hosts and ensured that we had a
                  great time and at the same time stayed safe! The equipments
                  were well maintained and needless to mention we had a great
                  one! Thank you Mr and Mrs Foo!"
                </P>
              </ReviewContent>
              <ReviewStars rating={5} />
              <P>Richie Kaye</P>
            </ReviewContainer>
            <ReviewContainer>
              <ReviewContent>
                <h2>"Excellent service"</h2>
                <P>
                  "Great experience!! Excellent service by Uncle Foo and his
                  family! My 11 yrs old son loved the trip!!! One of the outing
                  highly recommended. We will be back!"
                </P>
              </ReviewContent>
              <ReviewStars rating={5} />
              <P>Sam Chee</P>
            </ReviewContainer>
          </ReviewsContainer>
        </MaxWidthWrapper>

        <FullWidthWrapper>
          <HelpContentContainer id="contact">
            <h2>Need help?</h2>
            <P>
              The quickest way to get in touch with us is via Whatsapp at{" "}
              <a href="tel:+6582995118">
                <b>+65&nbsp;8299&nbsp;5118</b>
              </a>
            </P>
            <P>
              or connect with us directly via Whatsapp Web{" "}
              <div
                style={{
                  width: "100px",
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              >
                <StyledImg src={WhatsappWebButton}></StyledImg>
              </div>
            </P>
            <br />
            <P>
              Check out some helpful responses we've put together on our{" "}
              <Link to="/faq" onClick={scrollToTop}>
                <b>FAQ&nbsp;Page</b>
              </Link>
            </P>
          </HelpContentContainer>
        </FullWidthWrapper>

        <MaxWidthWrapper>
          <br />
          <h2 style={{ padding: "0 2em" }}>
            Join our friendly kayakpools community today!
          </h2>
          <PhotosContainer>
            <PhotoContainer>
              <StyledImg src={Photo1}></StyledImg>
            </PhotoContainer>
            <PhotoContainer>
              <StyledImg src={Photo2}></StyledImg>
            </PhotoContainer>
            <PhotoContainer>
              <StyledImg src={Photo3}></StyledImg>
            </PhotoContainer>
            <PhotoContainer>
              <StyledImg src={Photo4}></StyledImg>
            </PhotoContainer>
            <PhotoContainer>
              <StyledImg src={Photo5}></StyledImg>
            </PhotoContainer>
            <PhotoContainer>
              <StyledImg src={Photo6}></StyledImg>
            </PhotoContainer>
            <PhotoContainer>
              <StyledImg src={Photo7}></StyledImg>
            </PhotoContainer>
            <PhotoContainer>
              <StyledImg src={Photo8}></StyledImg>
            </PhotoContainer>
            <PhotoContainer>
              <StyledImg src={Photo9}></StyledImg>
            </PhotoContainer>
          </PhotosContainer>
        </MaxWidthWrapper>

        <BottomCTAContainer>
          <Link to="/book">
            <BookButton>BOOK YOUR KAYAKS NOW</BookButton>
          </Link>
        </BottomCTAContainer>
      </LandingWrapper>
    );
  }
}

export default Landing;
