import React from "react";
import styled from "styled-components";

import breakpoints from "../breakpoints";

const Wrapper = styled.div`
  > h2 {
    font-family: ${(props) => `${props.theme.fonts.secondary}`};
    font-size: 30px;
    margin-top: 0;
  }
  width: 100%;
  margin: 1em auto;
  @media only screen and ${breakpoints.device.md} {
    > h2 {
      font-family: ${(props) => `${props.theme.fonts.secondary}`};
      font-size: 40px;
      margin-top: 0;
    }
    margin: 2em auto;
    width: ${(props) => `${props.theme.width.content}`};
  }
`;

const MaxWidthWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default MaxWidthWrapper;
