import React from "react";
import styled from "styled-components";

const StyledP = styled.p`
  font-family: ${(props) =>
    props.secondaryFont
      ? `${props.theme.fonts.secondary}`
      : `${props.theme.fonts.primary}`};
  font-size: ${(props) =>
    props.xSmall
      ? `${props.theme.fontSize.xSmall}`
      : props.small
      ? `${props.theme.fontSize.small}`
      : props.large
      ? `${props.theme.fontSize.large}`
      : props.medium
      ? `${props.theme.fontSize.medium}`
      : props.xLarge
      ? `${props.theme.fontSize.xLarge}`
      : props.xxLarge
      ? `${props.theme.fontSize.xxLarge}`
      : `${props.theme.fontSize.xMedium}`};
  font-weight: ${(props) =>
    props.semiBold
      ? `${props.theme.fontWeight.semiBold}`
      : props.medium
      ? `${props.theme.fontWeight.medium}`
      : `${props.theme.fontWeight.regular}`};
  > a {
    color: black !important;
  }
  color: ${(props) => (props.blue ? `${props.theme.colors.blue}` : "black")};
`;

const P = ({ children, ...props }) => {
  return <StyledP {...props}>{children}</StyledP>;
};

export default P;
