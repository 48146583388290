import React from "react";
import { ThemeProvider } from "styled-components";

const BASE = 4;

const borderWidths = {
  sm: "1px",
  md: "3px",
};

const borderStyles = {
  solid: "solid",
};

const theme = {
  borders: {
    sm: `${borderWidths.sm} ${borderStyles.solid}`,
    md: `${borderWidths.md} ${borderStyles.solid}`,
  },
  lineHeights: {
    sm: `${BASE * 4}px`,
    md: `${BASE * 5}px`,
    lg: `${BASE * 6}px`,
    xl: `${BASE * 7}px`,
    xxl: `${BASE * 8}px`,
    xxxl: `${BASE * 11}px`,
  },
  space: {
    base: BASE,
    xxs: `${BASE}px`,
    xs: `${BASE * 2}px`,
    sm: `${BASE * 3}px`,
    md: `${BASE * 5}px`,
    lg: `${BASE * 8}px`,
    xl: `${BASE * 10}px`,
    xxl: `${BASE * 12}px`,
  },
  borderWidths: {
    sm: "1px",
  },
  borderStyles: {
    solid: "solid",
  },
  colors: {
    blue: "#0A1274",
    orange: "#FF8E16",
    lightGrey: "#FAF9F9",
    primaryHue: "#0A1274",
    dangerHue: "red",
    warningHue: "yellow",
    successHue: "green",
    neutralHue: "grey",
    chromeHue: "kale",
  },
  fonts: {
    primary: "Montserrat",
    secondary: "Caveat Brush",
    sansSerif: "sans-serif",
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
  },
  width: {
    content: "980px",
  },
  fontSize: {
    xSmall: "12px",
    small: "14px",
    medium: "16px",
    xMedium: "18px",
    xxMedium: "20px",
    large: "24px",
    xLarge: "30px",
    xxLarge: "40px",
  },
  fontSizes: {
    md: "14px",
  },
  shadows: {
    sm: (color) => `0 0 0 2 ${color}`,
  },
  borderRadii: {
    sm: `${BASE / 2}px`,
    md: `${BASE}px`,
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
