import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import breakpoints from "../breakpoints";

import LogoImage from "../assets/nav/logo.png";

import BookButton from "../components/BookButton";

const NavWrapper = styled.div`
  padding: 20px 10px;
  background: white;
  justify-content: space-between;
  align-items: center;
  > a {
    text-decoration: none !important;
  }
  @media only screen and ${breakpoints.device.sm} {
    display: flex;
    padding: 1em 3em 0.5em 3em;
  }
`;
const LogoContainer = styled.div``;

const LogoImageImage = styled.img`
  width: 90px;
  @media only screen and ${breakpoints.device.sm} {
    width: 120px;
  }
`;

const LogoText = styled.p`
  display: none;
  @media only screen and ${breakpoints.device.sm} {
    display: block;
    font-size: 18px;
    margin-top: 0px;
    margin-left: -20px;
    margin-bottom: 5px;
    font-family: ${(props) => `${props.theme.fonts.secondary}`};
    font-weight: ${(props) => `${props.theme.fontWeight.semiBold}`};
    color: ${(props) => `${props.theme.colors.blue}`};
  }
`;
const NavItemsUL = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
  padding-top: 15px;
  @media only screen and ${breakpoints.device.sm} {
    width: 600px;
    padding-top: 0;
  }
`;

const NavItemsList = styled.li`
  font-size: ${(props) => `${props.theme.fontSize.small}`};
  flex: 1;
  color: ${(props) => `${props.theme.colors.blue}`};
  font-family: ${(props) => `${props.theme.fonts.primary}`};
  font-weight: ${(props) => `${props.theme.fontWeight.semiBold}`};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  > a {
    text-decoration: none;
    color: ${(props) => `${props.theme.colors.blue}`} !important;
  }
  @media only screen and ${breakpoints.device.sm} {
    width: 600px;
    font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
  }
`;

const BookButtonContainer = styled.div`
  display: none;
  @media only screen and ${breakpoints.device.sm} {
    display: block;
  }
`;

class Nav extends React.Component {
  render() {
    return (
      <NavWrapper>
        <Link to="/">
          <LogoContainer>
            <LogoImageImage src={LogoImage}></LogoImageImage>
            <LogoText>KAYAK POOLS</LogoText>
          </LogoContainer>
        </Link>
        <NavItemsUL>
          <NavItemsList>
            <HashLink to="/#trips">TRIPS</HashLink>
          </NavItemsList>
          <NavItemsList>
            <HashLink to="/#contact">CONTACT</HashLink>
          </NavItemsList>
          <NavItemsList>
            <Link to="/about">ABOUT US</Link>
          </NavItemsList>
          <NavItemsList>
            <Link to="/faq">FAQ</Link>
          </NavItemsList>
          <BookButtonContainer>
            <Link to="/book">
              <BookButton>BOOK</BookButton>
            </Link>
          </BookButtonContainer>
        </NavItemsUL>
      </NavWrapper>
    );
  }
}

export default Nav;
