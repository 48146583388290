import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

import Theme from "./theme";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

import Landing from "./views/Landing";
import AboutUs from "./views/AboutUs";
import FAQ from "./views/FAQ";
import Book from "./views/Book";

function App() {
  return (
    <div className="App">
      <Router>
        <Theme>
          <Nav />
          <div className="PageContainer" id="PageContainer">
            <div className="ContentWrap">
              <Switch>
                <Route path="/about">
                  <AboutUs />
                </Route>
                <Route path="/faq">
                  <FAQ />
                </Route>

                <Route path="/book">
                  <Book />
                </Route>
                <Route path="/">
                  <Landing />
                </Route>
              </Switch>
            </div>
            <Footer />
          </div>
        </Theme>
      </Router>
    </div>
  );
}

export default App;
