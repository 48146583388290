import { render } from "@testing-library/react";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: ${(props) => `${props.theme.colors.lightGrey}`};
  padding: 2em;
`;

const FullWidthWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default FullWidthWrapper;
