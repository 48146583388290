import React from "react";

class Banner extends React.Component {
  render() {
    const bannerStyles = {
      backgroundColor: "#FF8E16",
      padding: "20px",
      textAlign: "center",
      color: "white",
      fontFamily: "Caveat Brush",
    };

    const strikethroughStyles = {
      textDecoration: "line-through",
    };

    const h2Styles = {
      margin: "5px",
      fontSize: "35px",
    };

    const pStyles = {
      margin: "2px",
      fontSize: "20px",
    };

    return (
      <div style={bannerStyles}>
        <h2 style={h2Styles}>2nd Anniversary Celebration!</h2>
        <p style={pStyles}>
          Join us for our special promotion from August till the end of the
          year. Don't miss out on this amazing discount!
        </p>
        <br />
        <p style={pStyles}>
          Single Kayak: <span style={strikethroughStyles}>$80</span> $60
        </p>
        <p style={pStyles}>
          Tandem Kayak: <span style={strikethroughStyles}>$120</span> $100
        </p>
      </div>
    );
  }
}

export default Banner;
