import React, { Component, useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";

class Book extends React.Component {
  // componentDidMount() {
  //   let script = document.createElement("script");
  //   script.setAttribute("id", "firstScript");
  //   script.onload = function () {
  //     let newScript = document.createElement("script");
  //     let inlineScript = document.createTextNode(
  //       'var widget = new SimplybookWidget({"widget_type":"iframe","url":"https://kayakpools.simplybook.asia","theme":"space","theme_settings":{"timeline_hide_unavailable":"1","sb_base_color":"#ff8e16","hide_past_days":"0","timeline_show_end_time":"0","timeline_modern_display":"as_slots","display_item_mode":"block","sb_review_image":"","dark_font_color":"#474747","light_font_color":"#ffffff","sb_company_label_color":"#ffffff","hide_img_mode":"0","show_sidebar":"1","sb_busy":"#dad2ce","sb_available":"#d3e0f1"},"timeline":"flexible_provider","datepicker":"top_calendar","is_rtl":false,"app_config":{"allow_switch_to_ada":0,"predefined":[]}});'
  //     );
  //     newScript.setAttribute("id", "secondScript");
  //     newScript.appendChild(inlineScript);
  //     const bookPage = document.getElementById("bookPage");
  //     console.log(bookPage);
  //     document.getElementById("bookPage").appendChild(newScript);
  //   };
  //   script.src = "//widget.simplybook.asia/v2/widget/widget.js";
  //   document.getElementById("bookPage").appendChild(script);
  // }

  componentDidMount() {
    const iFrame = document.getElementById("iframe");
    iFrame.style.display = "block";
    // iFrame.style.display = "none";
    iFrame.style.position = "absolute";
    iFrame.style.top = "113px";
    iFrame.style.width = "100%";
    iFrame.style.margin = "0 auto";
    iFrame.style.left = "0";
    iFrame.style.right = "0";

    const root = document.getElementById("iframe");
    root.scrollIntoView(true);
  }

  render() {
    window.addEventListener("message", console.log("asldkfjalkdjf"));
    return <div id="bookPage"></div>;
  }
}
export default Book;
