import React, { useEffect } from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

import QrCode from "../assets/landing/qrCode.png";

import breakpoints from "../breakpoints";
import MaxWidthWrapper from "../components/MaxWidthWrapper";
import FullWidthWrapper from "../components/FullWidthWrapper";
import BookButton from "../components/BookButton";

import P from "../components/P";

const QRCodeContainer = styled.div`
  margin-top: 20px;
  width: 190px;
  margin-bottom: 20px;
  @media only screen and ${breakpoints.device.sm} {
    width: 300px;
  }
`;

const StyledImg = styled.img`
  width: 100%;
`;

const FAQWrapper = styled.div`
  text-align: left;
  h2 {
    font-family: ${(props) => `${props.theme.fonts.secondary}`};
    font-size: ${(props) => `${props.theme.fontSize.xLarge}`};
    text-align: center;
    @media only screen and ${breakpoints.device.sm} {
      margin-bottom: 2em;
      margin-top: 2em;
    }
  }
  li {
    margin-top: 10px;
  }
  a {
    color: inherit !important;
  }
`;

const Divider = styled.hr`
  width: 100%;
  margin: 0 auto;
  max-width: ${(props) => `${props.theme.width.content}`};
  border-top: 1px solid grey;
`;

const QuoteContainer = styled.div`
  text-align: center;
  padding: 2em;

  p {
    margin-bottom: 0;
    // margin-top: 0;
    font-size: ${(props) => `${props.theme.fontSize.large}`};
    @media only screen and ${breakpoints.device.sm} {
      font-size: ${(props) => `${props.theme.fontSize.xxLarge}`};
    }
  }
`;

const FAQSectionWrapper = styled.div`
  p {
    font-size: ${(props) => `${props.theme.fontSize.medium}`};
    @media only screen and ${breakpoints.device.sm} {
      font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
    }
  }
`;

const ExploreTripsContainer = styled.div`
  margin-bottom: 2em;
`;

class FAQ extends React.Component {
  componentDidMount() {
    const iFrame = document.getElementById("iframe");
    iFrame.style.display = "none";
    const root = document.getElementById("root");
    root.scrollIntoView();
  }

  render() {
    return (
      <FAQWrapper>
        <FullWidthWrapper>
          <MaxWidthWrapper>
            <h2>FAQs</h2>
            <P xLarge semiBold secondaryFont>
              General
            </P>
            <FAQSectionWrapper>
              <P semiBold>Where is the meeting point?</P>
              <P>
                Pasir Ris Beach Park, to the left of KOKOMO Beach Club (direct
                entrance to the sea)
              </P>
              <P>
                <li>Nearest carparks: Pasir Ris Car park E&nbsp;&&nbsp;F</li>
                <li>
                  <a
                    href="https://www.google.com/maps/dir//1.385632,103.9444436"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google Map Coordinates
                  </a>
                </li>
              </P>
              <br />
              <P semiBold>What do I need to bring?</P>
              <P>
                <li>💧 Drinking water</li>
                <li>🍩 Some snacks</li>
                <li>🧴 Sunblock</li>
                <li>
                  👖 Clothing to protect from the sun (long sleeves/pants)
                </li>
                <li>😎 Sunglassses</li>
                <li>🧢 Sun hat</li>
                <li>🎒 Waterproof bag for valuables</li>
                <li>🧥 Raincoat/Umbrella</li>
                <li>🔋 Power bank to recharge your phone</li>
                <li>🔆 Energy for kayaking 😊</li>
                <br />
                <P>
                  We strongly encourage you to download the <b>myENV app</b> by
                  the National Environment Agency (NEA) to monitor weather
                  conditions when out at sea.
                </P>
              </P>
              <br />
              <P semiBold>Is it safe?</P>
              <P>
                The open, sit-on-top design makes it really safe and easy even
                for beginners.
              </P>
              <br />
              <P semiBold>
                Do I need any kayaking certification to rent and use these
                kayaks?
              </P>
              <P>
                No kayaking certification is required for pedal kayaks. Similar
                to sit-on-top kayaks, leisure kayakers without any certification
                are allowed to use pedal kayaks.
              </P>
              <br />
              <P semiBold>What is provided?</P>
              <P>
                <li>Personal Floating Device (Life Jacket)</li>
                <li>
                  Option to rent fishing gear if required. Please get in touch
                  with us for more information about it.
                </li>
              </P>
              <br />
              <P semiBold>Are the kayaks fixed with fishing rod holders?</P>
              <P>
                There are <b>4 built-in </b>rold holders on all of our kayaks.
                <br />
                <br />
                Tandem kayaks: <b>2 additional </b> standalone rod holders
                <br />
                Single kayaks: <b>1 additional </b> standalone rold holder
              </P>
              <br />
              <P semiBold>Are the public bathrooms nearby?</P>
              <P>Yes, there are public bathrooms close to the meeting point</P>
              <br />
              <P semiBold>Are there any weight restrictions?</P>
              <P>There is a weight capacity of 100 kilograms per kayak seat.</P>
              <br />
              <P semiBold>Is there a height requirement?</P>
              <P>
                For your safety and comfort, you should be at least 1.45meters
                tall in order to use the pedal kayaks.
              </P>
              <br />

              <P semiBold>What happens in the event of bad weather?</P>
              <P>
                We can arrange for you to postpone the trip in the event of
                inclement weather. This will not be considered as a
                rescheduling.
              </P>
              <br />
              <P semiBold>How do you contact us?</P>
              <P>
                You can drop us a Whatsapp message or call us at this number{" "}
                <a href="tel:+6582995118">
                  <b>+65 8299 5118</b>
                </a>
              </P>
            </FAQSectionWrapper>
            <br />
            <Divider></Divider>
            <P xLarge semiBold secondaryFont>
              Booking & Payments
            </P>
            <FAQSectionWrapper>
              <P semiBold>How are payments made?</P>
              <P>
                Our team will get in touch with you to secure the payment and
                confirm your booking within 24 hours of your booking submission.
                <br />
                <br />
                We accept the following payment methods:
                <br />
                <br />
                <ul>
                  <li>PayNow</li>
                  <ul>
                    <li>Via UEN Number: T21LL1113D</li>
                    <li>
                      Via QR code
                      <QRCodeContainer>
                        <StyledImg src={QrCode}></StyledImg>
                      </QRCodeContainer>
                    </li>
                  </ul>
                  <br />
                  <li>Bank Transfer (DBS Account No.: 0720438000)</li>
                </ul>
              </P>
              <br />
              <P semiBold>What happens to my payment if I can't make it?</P>
              <P>
                Contact us for complimentary rescheduling for up to 3 times.
                Please give us at least 3 days advance notice for any
                rescheduling. A fee may be incurred on subsequent rescheduling.
              </P>
            </FAQSectionWrapper>
            <br />
            <Divider></Divider>
            <P xLarge semiBold secondaryFont>
              Trip Details
            </P>
            <FAQSectionWrapper>
              <P semiBold>What can we expect after making a reservation?</P>
              <P>
                You will receive an email acknowledging your booking submission.
                Subsequently, our team will be in touch with you within 24 hours
                regarding payments and other trip details.
              </P>
              <br />
              <P semiBold>What can we expect on the day of the outing?</P>
              <P>
                <li>
                  At the meeting point, we will introduce the kayak to you and
                  provide an explanation on the different parts and how to use
                  them.
                </li>
                <li>
                  We will get you comfortably settled in the kayak and see that
                  you are safely off on your trip.
                </li>
                <li>
                  About 30 mins before returning back to shore, please let us
                  know so that we can come to help you to berth the kayak and
                  get you back on shore safely.
                </li>
                <li>
                  At any point during the trip should you require help, we can
                  be contacted. We also have a motorised dinghy to get you back
                  to shore should any unexpected situations arise.
                </li>
              </P>
            </FAQSectionWrapper>
          </MaxWidthWrapper>
        </FullWidthWrapper>
        <MaxWidthWrapper>
          <QuoteContainer>
            <P xxLarge>
              "A day spent on the water <br />
              is always a day well spent.”
            </P>
            <br />
            <br />
            <ExploreTripsContainer>
              <HashLink to="/#trips">
                <BookButton>Explore our trips!</BookButton>
              </HashLink>
            </ExploreTripsContainer>
          </QuoteContainer>
        </MaxWidthWrapper>
      </FAQWrapper>
    );
  }
}

export default FAQ;
