import React from "react";
import styled from "styled-components";

import breakpoints from "../breakpoints";
import P from "../components/P";
import MaxWidthWrapper from "../components/MaxWidthWrapper";

import HeroImage from "../assets/aboutUs/heroImage1.jpg";
import UncleFoo2 from "../assets/aboutUs/unclefoo2.jpg";
import UncleFoo3 from "../assets/aboutUs/unclefoo3.jpg";

const AboutUsWrapper = styled.div`
  line-height: 25px;
  text-align: left;
  padding: 2em;
  padding-top: 0;
  @media only screen and ${breakpoints.device.sm} {
    padding-top: 2em;
  }
  h2 {
    font-family: ${(props) => `${props.theme.fonts.secondary}`};
    font-size: ${(props) => `${props.theme.fontSize.xLarge}`};
    text-align: center;
    margin-bottom: 1em;
    margin-top: 1em;
    @media only screen and ${breakpoints.device.sm} {
      margin-bottom: 2em;
      margin-top: 0em;
    }
  }
  p {
    font-size: ${(props) => `${props.theme.fontSize.medium}`};
    @media only screen and ${breakpoints.device.sm} {
      font-size: ${(props) => `${props.theme.fontSize.xMedium}`};
    }
  }
`;

const HeroImageContainer = styled.div`
  display: block;
  background-image: url(${HeroImage});
  background-size: cover;
  background-position: center;
  height: 200px;
  width: 100vw;
  position: relative;
  background-repeat: no-repeat;
  @media only screen and ${breakpoints.device.sm} {
    display: block;
    background-image: url(${HeroImage});
    background-size: cover;
    background-position: center;
    height: 700px;
    width: 100vw;
    position: relative;
    background-repeat: no-repeat;
  }
`;

const StyledImg = styled.img`
  width: 100%;
`;

const ImageAndTextContainer = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  @media only screen and ${breakpoints.device.sm} {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
    margin-bottom: 2em;
    align-items: center;
  }
`;

const ImageContainer = styled.div`
  flex: 2;
`;

const TextContainerRight = styled.div`
  @media only screen and ${breakpoints.device.sm} {
    margin-left: 3em;
    flex: 3;
  }
`;
const TextContainerLeft = styled.div`
  order: 2;
  @media only screen and ${breakpoints.device.sm} {
    margin-right: 3em;
    order: initial;
    flex: 2;
  }
`;

class AboutUs extends React.Component {
  componentDidMount() {
    const iFrame = document.getElementById("iframe");
    iFrame.style.display = "none";
    const root = document.getElementById("root");
    root.scrollIntoView();
  }

  render() {
    return (
      <>
        <HeroImageContainer></HeroImageContainer>
        <MaxWidthWrapper>
          <AboutUsWrapper>
            <h2>Our Story</h2>
            <P xMedium>
              We are a small, family-run kayak enterprise in the north-eastern
              shore of Singapore, close to the sea and nature of Pasir Ris Beach
              Park.
            </P>
            <ImageAndTextContainer>
              <ImageContainer>
                <StyledImg src={UncleFoo2}></StyledImg>
              </ImageContainer>
              <TextContainerRight>
                <P xMedium>
                  With a sense of adventure and the love of the sea and fishing
                  since he was a young boy, Uncle Foo was inspired by the idea
                  of venturing out to sea in a self-sufficient and flexible
                  manner to fish. After much research, he acquired his first
                  Hobie kayak and found it to be safe, stable, and comfortable.
                  His outings became so enjoyable that he became hooked. To
                  share the experience with his family, he bought several more
                  kayaks. After several enquiries by those who encountered him
                  at the park, he started sharing the kayaks with a small
                  service fee for launching, recovery and washing. By word of
                  mouth, more requests started coming in.
                </P>

                <P xMedium>And so, Kayak Pools was born.</P>
                <P xMedium>
                  We aim to share this love of the sea and our surroundings by
                  making Hobie kayaks accessible and affordable to all. Hobie
                  kayaks have both the hand and leg paddles, and are easily
                  manoeuvred. No prior experience is needed. Each kayak is safe,
                  comfortable, and equipped for fishing. We will explain the
                  workings of the kayak, provide you with a life jacket, and
                  help you to get settled in the kayak for your trip.
                </P>
              </TextContainerRight>
            </ImageAndTextContainer>

            <ImageAndTextContainer>
              <TextContainerLeft>
                <P xMedium>
                  You may be surprised to discover what else our beautiful
                  country has to offer: the brilliant sunrises and sunsets,
                  canals and waterways of different shades of colours, the calm
                  and tranquillity as you paddle on, away from the hustle and
                  bustle of the city. Each kayaking experience is unique and as
                  memorable as you make it to be.
                </P>
              </TextContainerLeft>
              <ImageContainer>
                <StyledImg src={UncleFoo3}></StyledImg>
              </ImageContainer>
            </ImageAndTextContainer>
            <P xMedium>
              For us, this is more than just an enterprise. It is also about the
              opportunities to meet and interact with a warm, friendly community
              of people from all over Singapore who share a sense of adventure
              and enjoy exploring the outdoors. We always look forward to
              meeting you, our returning and new kayaking friends, and enjoy
              helping you create your kayaking adventures.
            </P>
            <br />
            <P>So welcome onboard!</P>
            <br />
          </AboutUsWrapper>
        </MaxWidthWrapper>
      </>
    );
  }
}

export default AboutUs;
