import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import breakpoints from "../breakpoints";
import FullWidthWrapper from "../components/FullWidthWrapper";
import MaxWidthWrapper from "../components/MaxWidthWrapper";
import P from "../components/P";

const FooterWrapper = styled.div`
  position: absolute;
  // bottom: 0;
  width: 100%;
`;

const FooterNav = styled.div`
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  a {
    text-decoration: none !important;
  }
  p {
    flex: 1;
  }
  @media only screen and ${breakpoints.device.sm} {
    width: 500px;
    display: flex;
  }
`;

class Footer extends React.Component {
  render() {
    return (
      <FooterWrapper>
        <FullWidthWrapper>
          <FooterNav>
            <P small>
              <HashLink to="/#trips">Trips</HashLink>
            </P>
            <P small>
              <HashLink to="/#contact">Contact</HashLink>
            </P>
            <P small>
              <Link to="/about">About Us</Link>
            </P>
            <P small>
              <Link to="/faq">FAQ</Link>
            </P>
          </FooterNav>
          <br />
          <P small medium>
            Whatsapp: <a href="tel:+6582995118">+65 8299 5118</a>
            <br />
            Email:&nbsp;
            <a href="mailto: kayakpools.sg@gmail.com">
              kayakpools.sg@gmail.com
            </a>
            <br />
            Instagram:{" "}
            <a
              href="https://www.instagram.com/kayakpools.sg/"
              target="_blank"
              rel="noreferrer"
            >
              kayakpools.sg
            </a>
            <br />
            <br />© Copyright 2021 Kayak&nbsp;Pools&nbsp;LLP&nbsp;Singapore
            <br />
            <div style={{ paddingTop: "6px" }}>
              <em style={{ fontSize: "12px" }}>
                Photo credits: Joe Tham Studio
              </em>
            </div>
          </P>
        </FullWidthWrapper>
      </FooterWrapper>
    );
  }
}

export default Footer;
